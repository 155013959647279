html,
body {
    height: 100%;
    width: 100%;
}

body {
    @include serif-font;
}

hr {
    color: $theme-primary;
    border-width: 3px;
    max-width: 50px;
    margin: 20px auto;
    opacity: 1;

}

hr:not([size]) {
    height: 3px;
}


hr.light {
    color: white;
}

a {
    @include transition-all;
    color: $theme-primary !important;
    &:hover,
    &:focus {
        color: darken($theme-primary, 10%);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include sans-serif-font;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.bg-primary {
    background-color: $theme-primary !important;
    color: white;
}

.bg-dark {
    background-color: $theme-dark !important;
    color: white;
}

.text-faded {
    color: rgba(white, .75);
}

section {
    padding: 100px 0;
}

aside {
    padding: 50px 0;
}

.no-padding {
    padding: 0;
}

// Navigation

.navbar-default {
    background-color: white;
    border-color: rgba($theme-dark, .5);
    @include sans-serif-font;
    @include transition-all;
    .navbar-header .navbar-brand {
        color: $theme-primary !important;
        @include sans-serif-font;
        font-weight: 700;
        text-transform: uppercase;
        &:hover,
        &:focus {
            color: darken($theme-primary, 10%);
        }
    }
    .nav {
        > li {
            > a,
            > a:focus {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 13px;
                color: $theme-dark;
                &:hover {
                    color: $theme-primary;
                }
            }
            &.active {
                > a,
                > a:focus {
                    color: $theme-primary;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
//    @media (min-width: 768px) {
//        background-color: transparent;
//        border-color: rgba(white, .3);
//        .navbar-header .navbar-brand {
//            color: rgba(white, .7);
//            &:hover,
//            &:focus {
//                color: white;
//            }
//        }
//        .nav > li > a,
//        .nav > li > a:focus {
//            color: rgba(white, .7);
//            &:hover {
//                color: white;
//            }
//        }
//        &.affix {
//            background-color: white;
//            border-color: rgba($theme-dark, .5);
//            .navbar-header .navbar-brand {
//                color: $theme-primary;
//                font-size: 14px;
//                &:hover,
//                &:focus {
//                    color: darken($theme-primary, 10%);
//                }
//            }
//            .nav > li > a,
//            .nav > li > a:focus {
//                color: $theme-dark;
//                &:hover {
//                    color: $theme-primary;
//                }
//            }
//        }
//    }
}

// Homepage Header

header {
    position: relative;
    width: 100%;
    min-height: auto;
    @include background-cover;
    background-position: center;
    background-image: url('../img/header.jpg');
    text-align: center;
    color: white;
    .header-content {
        position: relative;
        text-align: center;
        padding: 100px 15px 100px;
        width: 100%;
        .header-content-inner {
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
            }
            hr {
                margin: 30px auto;
            }
            p {
                font-weight: 300;
                color: rgba(white, .7);
                font-size: 16px;
                margin-bottom: 50px;
            }
        }
    }
//    @media (min-width: 768px) {
//        min-height: 100%;
//        .header-content {
//            position: absolute;
//            top: 50%;
//            -webkit-transform: translateY(-50%);
//            -ms-transform: translateY(-50%);
//            transform: translateY(-50%);
//            padding: 0 50px;
//            .header-content-inner {
//                max-width: 1000px;
//                margin-left: auto;
//                margin-right: auto;
//                p {
//                    font-size: 18px;
//                    max-width: 80%;
//                    margin-left: auto;
//                    margin-right: auto;
//                }
//            }
//        }
//    }
}

// Sections

.section-heading {
    margin-top: 0;
}

.service-box {
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: 992px) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
    h3 {
        margin-top: 20px;
    }
}

.schedule-table {
    h3 {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.portfolio-box {
    position: relative;
    display: block;
    max-width: 650px;
    margin: 0 auto;
    .portfolio-box-caption {
        color: white;
        opacity: 0;
        display: block;
        background: rgba( $theme-primary, .9 );
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        @include transition-all;
        .portfolio-box-caption-content {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .project-category,
            .project-name {
                @include sans-serif-font;
                padding: 0 15px;
            }
            .project-category {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
            }
            .project-name {
                font-size: 18px;
            }
        }
    }
    &:hover {
        .portfolio-box-caption {
            opacity: 1;
        }
    }
//    @media (min-width: 768px) {
//        .portfolio-box-caption {
//            .portfolio-box-caption-content {
//                .project-category {
//                    font-size: 16px;
//                }
//                .project-name {
//                    font-size: 22px;
//                }
//            }
//        }
//    }
}

.call-to-action {
    h2 {
        margin: 0 auto 20px;
    }
}

// Bootstrap Overrides
.text-primary {
    color: $theme-primary !important;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

// Button Styles
.btn-default {
    @include button-variant($theme-dark, white, white);
}

.btn-primary {
    @include button-variant(white, $theme-primary, $theme-primary);
}

.btn {
    @include sans-serif-font;
    border: none;
    border-radius: 300px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-xl {
    padding: 15px 30px;
}

// Contact
#contact .fa {
    color: $theme-dark;
    font-size: 4em;
}

// Schedule
#schedule {
    padding-bottom: 10px;
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

::selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $theme-dark;
}

