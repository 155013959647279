@charset "utf-8";

// Example Defaults
// $base-font-family: Helvetica, Arial, sans-serif;
// $base-font-size:   16px;
// $small-font-size:  $base-font-size * 0.875;
// $base-line-height: 1.5;

// $spacing-unit:     30px;

// $text-color:       #111;
// $background-color: #fdfdfd;
// $brand-color:      #2a7ae2;

// $grey-color:       #828282;
// $grey-color-light: lighten($grey-color, 40%);
// $grey-color-dark:  darken($grey-color, 25%);

// // Width of the content area
// $content-width:    800px;

// $on-palm:          600px;
// $on-laptop:        800px;

// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

// variables.less
$theme-primary: #e7a413; //#217dbb; //#ddce0a; //#e7a413; //#F05F40;
$theme-dark: #222;

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "mixins",
        "base"
;


// my changes

header {
    min-height: 40%;
}

header .header-content .header-content-inner p {
    color: white;
    margin-bottom: 10px;
    font-size: 22px;
}

header .header-content .header-content-inner h1 {
    font-family: 'Great Vibes', 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-weight: 100;
    text-transform: none;
}

section {
    padding: 50px 0;
}

.img-circle-contact {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    width:300px;
    height:300px;
    object-fit:cover;
    border-radius:50%;
    
    display: block;
    margin-left: auto;
    margin-right: auto;
}
